// External Dependencies
import { track } from "@amplitude/analytics-browser";
import { useRouteLoaderData } from "@remix-run/react";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { useCallback, useMemo } from "react";

export function useTracking() {
  let {
    ENV: { ENVIRONMENT, SEGMENT_API_KEY },
  } = useRouteLoaderData("root");

  const segment = useMemo(() => {
    return SEGMENT_API_KEY !== undefined
      ? AnalyticsBrowser.load({
          writeKey: SEGMENT_API_KEY,
        })
      : null;
  }, [SEGMENT_API_KEY]);

  // Function to track custom events
  const trackEvent = useCallback(
    ({ userId = null, event, properties = {} }) => {
      if (ENVIRONMENT === "production") {
        // Segment
        if (segment) {
          segment.track({
            userId,
            event,
            properties,
          });
        }

        // Amplitude
        track(event, properties, { ...properties, userId });
      } else {
        console.log({ userId, event, properties });
      }
    },
    [ENVIRONMENT, segment]
  );

  // Function to track page views specifically
  const trackPageView = useCallback(
    (pageData) => {
      if (ENVIRONMENT === "production" && segment) {
        segment.page({
          name: pageData.title,
          properties: {
            path: pageData.path,
            title: pageData.title,
            url: window.location.href,
            ...pageData.properties,
          },
        });

        track("Page View", {
          path: pageData.path,
          title: pageData.title,
          ...pageData.properties,
        });
      }
    },
    [ENVIRONMENT, segment]
  );

  const identifyUser = useCallback(
    (user) => {
      if (ENVIRONMENT === "production" && segment) {
        segment.identify(user.id, {
          ...user,
          company: user.organization,
        });
      }
    },
    [ENVIRONMENT, segment]
  );

  return { trackEvent, trackPageView, identifyUser };
}
